/**
 * Buttons are used based on their level of importance.
 * That allows us to create hierarchy in how much attention each type of button commands. In addition to type,
 * we have variations based on size for different use cases, like admin vs patient.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=1784%3A982
 */

import React, { MouseEventHandler } from "react";
import styled, { css } from "styled-components";
import {
  PrimaryBrandColor,
  Shade40Color,
  Shade60Color,
} from "../../styles/common.tsx";
import { alpha, darken } from "../../utils/colorManipulator.tsx";

type ButtonSize = "small" | "medium" | "large";
type ButtonVariant = "contained" | "outlined" | "text";

// TODO: Extend HTMLButton component props
interface Props {
  /**
   * The content of the component.
   */
  children: React.ReactNode;

  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'PrimaryBrandColor'
   */
  color?: string;

  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled?: boolean;

  /**
   * If `true`, the button will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;

  /**
   * The callback when a user clicks on the button.
   */
  onClick?: MouseEventHandler;

  /**
   * Plain buttons are associated with calls to action that do not require the
   * visual hierachy of a button. These are similar to links, but are used when
   * there is an action present (onClick such as open a modal, expand an accordion).
   * These must use $color-brand-primary or $shade-color-70.
   * @default false
   */
  plain?: boolean;

  /**
   * The size of the button.
   * Large buttons will always be fullWidth.
   * @default medium
   */
  size?: ButtonSize;

  /**
   * An icon to display before the children.
   */
  startIcon?: React.ReactNode;

  /**
   * The variant to use.
   * @default 'contained'
   */
  variant?: ButtonVariant;
}

function handleBackgroundColor(
  color: string,
  disabled: boolean,
  variant: ButtonVariant
): string {
  switch (variant) {
    case "contained":
      const backgroundColor = disabled ? Shade40Color : color;
      return `background-color: ${backgroundColor};`;
    default:
      return "background-color: transparent;";
  }
}

function handleBackgroundColorOnHover(
  color: string,
  disabled: boolean,
  variant: ButtonVariant
): string {
  if (disabled) {
    return "";
  }

  switch (variant) {
    case "contained":
      return `background-color: ${darken(color, 0.2)};`;
    default:
      return `background-color: ${alpha(color, 0.1)};`;
  }
}

function handleBorderStyle(
  color: string,
  disabled: boolean,
  variant: ButtonVariant
): string {
  switch (variant) {
    case "outlined":
      const borderColor = disabled ? Shade60Color : color;
      return `border: 1px solid ${borderColor};`;
    default:
      return "border: 0;";
  }
}

function handleColor(
  color: string,
  disabled: boolean,
  variant: ButtonVariant
): string {
  if (disabled) {
    return `color: ${Shade60Color};`;
  }

  switch (variant) {
    case "contained":
      return "color: white;";
    default:
      return `color: ${color};`;
  }
}

const HEIGHT_MAP: Record<ButtonSize, `height: ${number}px;`> = {
  small: "height: 38px;",
  medium: "height: 40px;",
  large: "height: 64px;",
};

const StyledButton = styled.button.attrs(
  (props: {
    color: string;
    disabled: boolean;
    fullWidth: boolean;
    plain: boolean;
    size: ButtonSize;
    variant: ButtonVariant;
  }) => ({
    color: props.color,
    disabled: props.disabled,
    fullWidth: props.fullWidth,
    plain: props.plain,
    size: props.size,
    variant: props.variant,
  })
)`
  // Font
  ${(props) => handleColor(props.color, props.disabled, props.variant)}
  font-style: normal;
  font-size: ${(props) => (props.size === "small" ? "14px" : "16px")};
  font-family: inherit;
  font-weight: 600;
  line-height: ${(props) => (props.size === "small" ? "18px" : "20px")};

  // Color
  ${(props) =>
    handleBackgroundColor(props.color, props.disabled, props.variant)}

  // Layout
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${(props) => HEIGHT_MAP[props.size]}
  ${(props) => handleBorderStyle(props.color, props.disabled, props.variant)}
  border-radius: 4px;
  padding: ${(props) => (props.size === "large" ? "26px" : "14px")} 16px;
  width: ${(props) =>
    props.fullWidth || props.size === "large" ? "100%" : "fit-content"};

  ${(props) =>
    props.plain &&
    css`
      color: ${props.color};
      border: 0;
      padding: 0;
      height: fit-content;
      width: fit-content;
      background-color: transparent;

      &:hover {
        background-color: transparent;
        color: ${darken(props.color, 0.2)};
      }
    `}

  // Pseudo-classes
  ${(props) =>
    !props.plain &&
    css`
      &:hover {
        ${handleBackgroundColorOnHover(
          props.color,
          props.disabled,
          props.variant
        )}
      }
    `}

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #000000;
  }
`;

const IconWrapper = styled.span`
  display: inherit;
  margin-right: 8px;
`;

const Button = React.forwardRef(
  (
    {
      children,
      color = PrimaryBrandColor,
      disabled = false,
      fullWidth = false,
      plain = false,
      size = "medium",
      startIcon,
      variant = "contained",
      ...rest
    }: Props,
    ref
  ) => {
    return (
      <StyledButton
        color={color}
        disabled={disabled}
        fullWidth={fullWidth}
        plain={plain}
        size={size}
        variant={variant}
        {...rest}
        ref={ref as any}
      >
        {startIcon && <IconWrapper>{startIcon}</IconWrapper>}
        {children}
      </StyledButton>
    );
  }
);

export default Button;
