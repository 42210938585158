import React, { useState } from "react";
import "../styles/auth.scss";
import Button from "../ui/components/Button/Button.tsx";
import { Link } from "react-router-dom";
import Header from "../components/Header";

const Auth = () => {
  const [selected, setSelected] = useState(true);
  return (
    <div>
      <Header />
      <div className="page">
        <div className="intro-section">
          Hello, Katherine. Let's validate you as a patient & pull up your
          details.
        </div>
        <div class="signup-form">
          <div class="form-group">
            <div>
              <label>Enter your last name</label>
            </div>
            <input type="text"></input>
          </div>
          <div class="form-group">
            <div>
              <label>Enter your date of birth</label>
            </div>
            <input type="date" placeholder="MM/DD/YYYY"></input>
          </div>
          <div className="form-group">
            <input
              type="checkbox"
              id="remember-me-checkbox"
              name="remember-me"
              checked={selected}
              onClick={(val) => setSelected(!selected)}
            />
            <label for="remember-me">Remember me for 30 days</label>
          </div>
          <div class="form-group">
            <Link to="/home">
              <Button type="submit">Sign in</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
