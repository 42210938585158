import React from "react";
import H5 from "../ui/components/Typography/H5.tsx";
import "../styles/card.scss";

const Card = (props) => {
  const { className = "card-default", icon } = props;
  return (
    <div className={className}>
      {icon && <img src={icon} alt="card-icon" />}
      <H5>All visits for the week of Nov 21-25</H5>
    </div>
  );
};

export default Card;
