import React from "react";
import ProviderDetails from "../ui/components/ProviderDetails/ProviderDetails.tsx";
import avatar from "../assets/avatar.png";

const ProviderCard = (props) => {
  return (
    <ProviderDetails
      src={avatar}
      address="Venture Hills Clinic - Orthopedics"
      occupation="Orthopedic surgeon"
      name="Rachel Dunham, DO"
      phoneNumber="(123) 456-7890"
    ></ProviderDetails>
  );
};

export default ProviderCard;
