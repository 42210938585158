import React, { useState } from "react";
import Header from "../components/Header";
import Button from "../ui/components/Button/Button.tsx";
import "../styles/home.scss";
import Modal from "../components/modals/Modal";
import ProviderCard from "../components/ProviderCard";
import Card from "../components/Card";
import info_icon from "../assets/info_icon.svg";

const CONFIRM = "CONFIRM";
const SCHEDULE = "SCHEDULE";

const Home = () => {
  const [confirmed, setConfirmed] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const openModal = ({ type }) => {
    setModalType(type);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Header />
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        type={modalType}
      ></Modal>
      <div className="page">
        <div>Hello, Katherine</div>
        <div>
          the following appointments are ready to be confirmed & registered for
          check-in
        </div>
        <Card icon={info_icon} />
        <ProviderCard />
        <div class="button-card">
          {confirmed
            ? "You're confirmed. Please register now"
            : "Are still able to attend?"}
          {confirmed ? (
            <div className="button-row">
              <Button onClick={() => openModal({ type: CONFIRM })}>
                Register this appointment
              </Button>
            </div>
          ) : (
            <div className="button-row">
              <Button onClick={() => setConfirmed(true)}>Yes</Button>
              <Button onClick={() => openModal({ type: SCHEDULE })}>No</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
