import React from "react";
import "../styles/header.scss";
import arrow from "../ui/assets/arrow-up.svg";
import logo from "../assets/logo.png";
import arrow_down from "../assets/arrow_down.svg";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const isAuthPage = window.location.href.includes("signup");
  return (
    <header className="header">
      <div>
        <img
          src={arrow}
          alt="arrow"
          className={isAuthPage ? "arrow inactive" : "arrow"}
          onClick={() => !isAuthPage && navigate(-1)}
        />
      </div>
      <div>
        <img src={logo} alt="logo" className="logo" />
      </div>
      <div className="lang-selector">
        <span>Eng </span>
        <img src={arrow_down} alt="arrow" />
      </div>
    </header>
  );
};

export default Header;
