import Home from "./pages/Home";
import Auth from "./pages/Auth";
import { createBrowserRouter, Route, Link } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/signup",
    element: <Auth />,
  },
]);

export default router;
