import React from "react";
import "../../styles/confirmappt.scss";

const ConfirmModalContent = (props) => {
  return (
    <div>
      <div>
        Reviewing your patient information on file to ensure everything is up to
        date. This may take a few moments to complete.
      </div>
    </div>
  );
};

export default ConfirmModalContent;
