import { keyframes } from 'styled-components';

export const PrimaryBrandColor = '#0B67FD';
export const PrimaryBrandColorDarker = '#0B5ED7';
export const SecondaryBrandColor = '#0F1B5D';
export const SecondaryBrandColorLighter = '#1f2e86';
export const TertiaryBrandColor = '#DBECFF';

export const Shade100Color = '#000000';
export const Shade90Color = '#171718';
export const Shade80Color = '#333333';
export const Shade70Color = '#6C6E88';
export const Shade60Color = '#8E8E8E';
export const Shade50Color = '#C4C4C4';
export const Shade40Color = '#DDDDDD';
export const Shade30Color = '#EEEEEE';
export const Shade20Color = '#F6F6F6';
export const Shade10Color = '#FBFBFC';
export const Shade00Color = '#FFFFFF';

export const SuccessStateColor = '#08A966';
export const WarningStateColor = '#FAB247';
export const ErrorStateColor = '#EA3F3F';

export enum ColorVariant {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY',
  Shade100 = 'SHADE_100',
  Shade90 = 'SHADE_90',
  Shade80 = 'SHADE_80',
  Shade70 = 'SHADE_70',
  Shade60 = 'SHADE_60',
  Shade50 = 'SHADE_50',
  Shade40 = 'SHADE_40',
  Shade30 = 'SHADE_30',
  Shade20 = 'SHADE_20',
  Shade10 = 'SHADE_10',
  Shade00 = 'SHADE_00',
  Success = 'SUCCESS',
  Warning = 'WARNING',
  Error = 'ERROR',
}

export const COLOR_VARIANT_TO_COLOR: Record<ColorVariant, string> = {
  [ColorVariant.Primary]: PrimaryBrandColor,
  [ColorVariant.Secondary]: SecondaryBrandColor,
  [ColorVariant.Tertiary]: TertiaryBrandColor,
  [ColorVariant.Shade100]: Shade100Color,
  [ColorVariant.Shade90]: Shade90Color,
  [ColorVariant.Shade80]: Shade80Color,
  [ColorVariant.Shade70]: Shade70Color,
  [ColorVariant.Shade60]: Shade60Color,
  [ColorVariant.Shade50]: Shade50Color,
  [ColorVariant.Shade40]: Shade40Color,
  [ColorVariant.Shade30]: Shade30Color,
  [ColorVariant.Shade20]: Shade20Color,
  [ColorVariant.Shade10]: Shade10Color,
  [ColorVariant.Shade00]: Shade00Color,
  [ColorVariant.Success]: SuccessStateColor,
  [ColorVariant.Warning]: WarningStateColor,
  [ColorVariant.Error]: ErrorStateColor,
};

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;
