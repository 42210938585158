import React from "react";
import ProviderCard from "../ProviderCard";
import Button from "../../ui/components/Button/Button.tsx";
import "../../styles/schedule.scss";

const ScheduleModalContent = (props) => {
  const updateAppt = () => {
    //update appt time
    props.closeModal();
  };

  const appointments = [
    {
      date: "Nov 18",
      day: "Tomorrow",
      times: ["1:30pm", "2:30pm"],
    },
    {
      date: "Nov 21",
      day: "Monday",
      times: ["8:00am", "1:15pm"],
    },
    {
      date: "Nov 22",
      day: "Tuesday",
      times: [
        "9:15pm",
        "10:00am",
        "11:15pm",
        "12:00am",
        "1:15pm",
        "2:00pm",
        "2:15pm",
        "3:00pm",
        "4:15pm",
        "5:00pm",
      ],
    },
    {
      date: "Nov 23",
      day: "Wednesday",
      times: ["9:00am", "12:15pm", "1:45pm", "2:30pm"],
    },
    {
      date: "Nov 24",
      day: "Thursday",
      times: ["1:00pm", "3:30pm", "4:30pm"],
    },
    {
      date: "Nov 25",
      day: "Friday",
      times: ["10:30am", "12:30pm"],
    },
    {
      date: "Nov 28",
      day: "Monday",
      times: ["8:30am", "10:15am", "11:00am", "1:30pm", "4:00pm"],
    },
  ];

  // const generateApptArray = () => {
  //   return new Array(getRandomInt(3, 8)).fill(0).map(generateApptTime).sort();
  // };

  return (
    <div>
      <ProviderCard />
      <div>
        Select a new time for your visit below, or cancel if you can no longer
        attend.
      </div>
      <div>
        {appointments.map((row, i) => {
          return (
            <div className="appt-row">
              <span className="date">{row.date}, </span>
              {row.day}
              <div className="appt-grid">
                {row.times.map((appt, i) => {
                  return <Button onClick={() => updateAppt()}>{appt}</Button>;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScheduleModalContent;
