import React from "react";
import ReactModal from "react-modal";
import "../../styles/modal.scss";
import ProviderCard from "../ProviderCard";
import Button from "../../ui/components/Button/Button.tsx";
import ScheduleModalContent from "./ScheduleModalContent";
import ConfirmModalContent from "./ConfirmModalContent";

const Modal = (props) => {
  const { modalIsOpen, closeModal } = props;

  const modalTitleMap = {
    SCHEDULE: "Modify Appointment",
    CONFIRM: "Registration: (Dr. Dunham, Sep 10)",
  };

  const modalContentMap = {
    SCHEDULE: <ScheduleModalContent closeModal={closeModal} />,
    CONFIRM: <ConfirmModalContent closeModal={closeModal} />,
  };

  return (
    <ReactModal
      isOpen={modalIsOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      // style={customStyles}
      contentLabel="Example Modal"
      className="modal"
      // overlayClassName="overlay"
      closeTimeoutMS={300}
    >
      <div className="modal">
        <div className="modal-header">
          <div className="title">{modalTitleMap[props.type]}</div>
          <button onClick={closeModal}>&#10005;</button>
        </div>
        <div className="modal-body" closeModal={closeModal}>
          {modalContentMap[props.type]}
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
